import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useQuery } from "@tanstack/react-query";
import { Filter } from "./filter";
import { Render } from "./render";
import { Search } from "./search";
import { getAllBlogPosts } from "../../utils/blog";
import { BlogPost } from "../../types/blog";
import { withDefault } from "../../layouts/Default";
import { useCallback, useState, useEffect } from "react";
import Loading, { LoadingWithDefault } from "../../components/Loading";

const metadata = {
  title: "Blog",
  description: "Read my thoughts on software development, design, and more.",
};

function Blog() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tag = searchParams.get("tag");
  const search = searchParams.get("search");

  const {
    data: allBlogs = [],
    isLoading,
    error,
  } = useQuery<BlogPost[]>({
    queryKey: ["blogPosts"],
    queryFn: getAllBlogPosts,
  });

  const [filteredBlogs, setFilteredBlogs] = useState<BlogPost[]>([]);

  useEffect(() => {
    if (allBlogs.length > 0) {
      let filtered = allBlogs.sort((a, b) =>
        new Date(a.metadata.publishedAt) > new Date(b.metadata.publishedAt) ? -1 : 1
      );

      if (tag) {
        filtered = filtered.filter(
          (post) => !post.metadata.hidden && post.metadata.tags.includes(tag.toLowerCase().trim())
        );
      }

      if (search) {
        filtered = filtered.filter(
          (post) =>
            !post.metadata.hidden &&
            (post.metadata.title.toLowerCase().includes(search.toLowerCase().trim()) ||
              post.metadata.summary.toLowerCase().includes(search.toLowerCase().trim()) ||
              post.metadata.tags.some((t) => t.toLowerCase().includes(search.toLowerCase().trim())))
        );
      }

      const showcaseBlogs = filtered.filter((post) => post.metadata.tags.includes("showcase"));
      const notShowcaseBlogs = filtered.filter((post) => !post.metadata.tags.includes("showcase"));
      const sortedShowcaseBlogs = showcaseBlogs.sort(
        (a, b) => parseInt(a.metadata.weight ?? "0") - Number(b.metadata.weight ?? "1000")
      );
      const sortedNotShowcaseBlogs = notShowcaseBlogs.sort(
        (a, b) => parseInt(a.metadata.weight ?? "0") - Number(b.metadata.weight ?? "1000")
      );

      setFilteredBlogs([...sortedShowcaseBlogs, ...sortedNotShowcaseBlogs]);
    }
  }, [allBlogs, tag, search]);

  const handleSearch = useCallback(
    (value: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      if (value) {
        newSearchParams.set("search", value);
      } else {
        newSearchParams.delete("search");
      }
      navigate(`?${newSearchParams.toString()}`);
    },
    [navigate, searchParams]
  );

  if (isLoading) return <Loading />;
  if (error) return <div>Error loading blog posts</div>;

  const allTags: string[] = Array.from(new Set(allBlogs.flatMap((post) => post.metadata.tags)));

  return (
    <section className="flex flex-col gap-9">
      <Helmet>
        <title>Blog</title>
        <meta
          name="description"
          content="Read my thoughts on software development, design, and more."
        />
      </Helmet>
      <Filter allTags={allTags} />
      <Search search={search} handleSearch={handleSearch} />
      <Render filteredBlogs={filteredBlogs} className="opacity-100 dark:opacity-100 pb-40" />
    </section>
  );
}

export default withDefault(<Blog />, metadata);
